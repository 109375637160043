import { AutoAwesome, Help } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ValChip = () => {
    const { t } = useTranslation();

    return (
        <Tooltip arrow title={ t('aiReports.thisContentWasGeneratedByVal') }>
            <Chip
                color="info"
                icon={<AutoAwesome />}
                label='Val'
                size='small'
                deleteIcon={<Help />}
                onDelete={() => {
                    // Not used for delete - adds a 'Help' icon instead
                }}
                sx={{
                    '@media print': { display: 'none' }
                }}
            />
        </Tooltip>
    );
};

export default ValChip;
