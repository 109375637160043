import PropTypes from 'prop-types';
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useMutation } from 'react-query';
import useAiSummaryReport from 'api-new/stim-based/useAiSummaryReport';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import vlqrApi, { handleError } from 'utils/vlqrApi';
import { LoadingButton } from '@mui/lab';
import { RestartAlt } from '@mui/icons-material';
import ValChip from './ValChip';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useTranslation } from 'react-i18next';
import DeleteForever from '@mui/icons-material/DeleteForever';

const AISummaryReport = ({ aiSummaryReportId, reportType, onDeleted, onReportRegenerated }) => {
    const { t } = useTranslation();
    const regenerateReportsFeatureIsEnabled = useFeatureIsOn('regenerate-ai-reports');
    const deleteReportsFeatureIsEnabled = useFeatureIsOn('delete-ai-reports');
    const { data: aiSummaryReport, isLoading, refetch } = useAiSummaryReport(aiSummaryReportId);

    const { mutate: regenerateReport, isLoading: isRegeneratingReport } = useMutation(() => {
        return vlqrApi.put(`aiSummaryReports/${aiSummaryReportId}/regenerate`);
    }, {
        onError: handleError,
        onSuccess: (data, variables, context) => {
            onReportRegenerated?.();
        }
    });

    const { mutate: deleteReport, isLoading: isDeletingReport } = useMutation(() => {
        return vlqrApi.delete(`aiSummaryReports/${aiSummaryReportId}`);
    }, {
        onError: handleError,
        onSuccess: (data, variables, context) => {
            refetch();
            onDeleted?.();
        }
    });

    if (isLoading) {
        return (
            <Stack gap={1}>
                <Typography variant="h6">
                    { t(`aiSystemInstructionType.${reportType}.reportTitle`) }
                </Typography>

                <Box sx={{ px: 2, py: 2, border: 'thin solid black', borderColor: 'grey.9', borderRadius: 2 }}>
                    <LoadingPlaceholder />
                </Box>
            </Stack>
        );
    }

    const { reportStatus, content, deletedOn } = aiSummaryReport;
    const isErrorState = reportStatus === AiSummaryReportStatus.ERROR;

    if (deletedOn) {
        return null;
    }

    const reportToolbar =
        <Stack
            direction='row'
            alignItems='center'
            gap={1}
            sx={{ '@media print': { display: 'none' } }}
        >
            { regenerateReportsFeatureIsEnabled &&
                <LoadingButton
                    loading={isRegeneratingReport}
                    loadingPosition="start"
                    startIcon={<RestartAlt />}
                    variant="outlined"
                    color="newPrimary"
                    onClick={() => regenerateReport()}
                >
                    { t('aiSummaryReport.regenerate') }
                </LoadingButton>
            }

            { deleteReportsFeatureIsEnabled &&
                <LoadingButton
                    loading={isDeletingReport}
                    loadingPosition="start"
                    startIcon={<DeleteForever />}
                    variant="outlined"
                    color="error"
                    onClick={() => deleteReport()}
                >
                    { t('common.buttons.delete') }
                </LoadingButton>
            }
        </Stack>;

    return (
        <Stack gap={1}>
            <Stack direction='row' alignItems='center' sx={{ backgroundColor: 'grey.10', padding: 1.5, borderRadius: 1 }}>
                <Stack direction='row' alignItems='center' gap={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="overline" sx={{ fontWeight: '500', lineHeight: 'normal' }}>
                        { t(`aiSystemInstructionType.${reportType}.reportTitle`) }
                    </Typography>

                    <ValChip />
                </Stack>

                { reportToolbar }
            </Stack>

            <Box>
                { isLoading &&
                    <LoadingPlaceholder />
                }

                { isErrorState &&
                    <Stack gap={2}>
                        <Alert severity='error'>
                            <AlertTitle>{ t('aiSummaryReport.reportGenerationFailed') }</AlertTitle>
                            { t('aiSummaryReport.reportGenerationFailedExplanation') }
                        </Alert>

                        <LoadingButton
                            loading={isRegeneratingReport}
                            loadingPosition="start"
                            startIcon={<RestartAlt />}
                            variant="contained"
                            color="newPrimary"
                            onClick={() => regenerateReport()}
                        >
                            { t('common.retry') }
                        </LoadingButton>
                    </Stack>
                }

                { content &&
                    <Box
                        sx={{
                            '& ul': {
                                paddingInlineEnd: '40px',
                                '& li': { marginBottom: 1 }
                            }
                        }}
                    >
                        <Markdown>
                            { content }
                        </Markdown>
                    </Box>
                }
            </Box>
        </Stack>
    );
};

AISummaryReport.propTypes = {
    aiSummaryReportId: PropTypes.string,
    onDeleted: PropTypes.func,
    reportType: PropTypes.string,
    onReportRegenerated: PropTypes.func
};

export default AISummaryReport;
