import PropTypes from 'prop-types';
import useAiSummaryReportsForFocusGroup from 'api-new/stim-based/useAiSummaryReportsForFocusGroup';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import React, { useRef } from 'react';
import NoReportsPlaceholder from './NoReportsPlaceholder';
import { Box, Button, GlobalStyles, Stack, Typography } from '@mui/material';
import AISummaryReport from './AISummaryReport';
import AiSystemInstructionType from 'enums/AiSystemInstructionType';
import { useReactToPrint } from 'react-to-print';
import { DateTime } from 'luxon';
import useBasicActivity from 'api-new/useBasicActivity';
import { Print } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useFocusGroupSchedule from 'api-new/useFocusGroupSchedule';

const byReportType = (a, b) => {
    if (a.reportType === AiSystemInstructionType.SESSION_KEY_TAKEAWAYS) {
        return -1;
    }

    if (a.reportType === AiSystemInstructionType.SESSION_SUMMARY) {
        return 1;
    }

    return 0;
};

const AIReportsPanel = ({ focusGroupId }) => {
    const printRef = useRef();
    const { t } = useTranslation();
    const { data: activityData, isLoading: isLoadingActivityData } = useBasicActivity(focusGroupId);
    const { data: activitySchedule, isLoading: isLoadingActivitySchedule } = useFocusGroupSchedule(focusGroupId);
    const { data: aiSummaryReports, isLoading: isLoadingReports, refetch: fetchAiSummaryReports } = useAiSummaryReportsForFocusGroup(focusGroupId);

    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    if (isLoadingActivityData || isLoadingActivitySchedule || isLoadingReports) {
        return <LoadingPlaceholder />;
    }

    if (aiSummaryReports.length === 0) {
        return (
            <Stack gap={4} sx={{ pt: 3, pb: 3 }}>
                <NoReportsPlaceholder
                    focusGroupId={focusGroupId}
                    onReportsGenerated={fetchAiSummaryReports}
                />
            </Stack>
        );
    }

    return (
        <Stack
            ref={printRef}
            gap={2}
            sx={{
                px: 2, pt: 4,
                '@media print': { padding: 0 }
            }}
        >
            <GlobalStyles styles={{
                // Set default margins for print view
                '@page': {
                    margin: '20mm 15mm 20mm 15mm !important;'
                }
            }}/>

            <Box>
                <Stack direction={'row'} alignItems={'flex-start'}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h5">
                            { activityData.description }
                        </Typography>

                        { activitySchedule?.actualStartTime &&
                            <Typography variant="subtitle1" gutterBottom>
                                { DateTime.fromISO(activitySchedule.actualStartTime).toLocaleString(DateTime.DATETIME_FULL) }
                            </Typography>
                        }
                    </Box>

                    <Button
                        startIcon={<Print />}
                        variant="outlined"
                        color="newPrimary"
                        onClick={() => handlePrint()}
                        sx={{ '@media print': { display: 'none' } }}
                    >
                        { t('common.buttons.print') }
                    </Button>
                </Stack>
            </Box>

            { aiSummaryReports.toSorted(byReportType).map(report => (
                <AISummaryReport
                    key={report.id}
                    aiSummaryReportId={report.id}
                    reportType={report.reportType}
                    onDeleted={fetchAiSummaryReports}
                    onReportRegenerated={fetchAiSummaryReports}
                />
            )) }
        </Stack>
    );
};

AIReportsPanel.propTypes = {
    focusGroupId: PropTypes.string
};

export default AIReportsPanel;
