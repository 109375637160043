export * from './app.config';

export const apiUrl = 'https://portalstaging.visionslive.com';
export const vlqrApiUrl = 'https://apistaging.visionslive.com/VL';
export const clientUrl = 'https://clientstaging.visionslive.com';

export const releaseNotesUri = 'https://visionslive-test.canny.io/changelog';

export const growthbookApiKey = 'sdk-pibWCduGBDFHtq7';
export const growthbookSecretKey = 'UaDfX2UxBolHIjlMpA0yUw==';
