import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getAiSummaryReports = async (focusGroupId) => {
    const { data } = await vlqrApi.get(`focusgroups/${focusGroupId}/aiSummaryReports`)
        .catch((error) => {
            handleError(error);
        });

    return data;
};

const useAiSummaryReportsForFocusGroup = (focusGroupId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['aiSummaryReportsForFocusGroup', focusGroupId],
        () => getAiSummaryReports(focusGroupId)
    );

    return { data, refetch, isLoading, isError };
};

export default useAiSummaryReportsForFocusGroup;
